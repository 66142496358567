import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BreadcrumbProps, Button, Select } from 'antd';
import { PageContainer } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';

import { breadCrumbItemRender } from 'components/helpers/breadcrumbs';
import { TEST_TOOL_ID } from 'utils/constants';

import { ToolSandbox } from './Sandbox';

import { getToolById } from 'api/tools';
import { getConnections } from 'api/connections';
import { gaEvent } from 'utils/ga';

export const ToolDetail: React.FC = () => {
    const { toolId } = useParams();
    const [ selectedConnectionId, setSelectedConnection ] = useState<string>();
    const [ refreshing, setRefreshing ] = useState(false);
    const [ frameSrc, setFrameSrc ] = useState('');
    const { data: tool, loading } = useRequest(getToolById, { defaultParams: [toolId || '']});
    const { data: connections, loading: loadingConnections } = useRequest(getConnections);
    const navigate = useNavigate();
    const searchParams = useSearchParams();

    useEffect(() => {
        if (!toolId) {
            return;
        }

        gaEvent('tool_use', { tool_id: toolId });
    }, [toolId]);

    if (!tool && !loading) {
        return <p>No tool found, how sad!</p>
    }

    const breadcrumbProps: BreadcrumbProps = {
        itemRender: breadCrumbItemRender,
        routes: [
            {
                path: `/`,
                breadcrumbName: 'Dashboard',
            },
            {
                path: `/tools`,
                breadcrumbName: `Tools`,
            },
            {
                path: `/tools/${ toolId }`,
                breadcrumbName: tool && !loading ? tool.name : 'Loading...',
            },
        ],
    };

    const connectionSelector = (
        <Select<string>
            value={selectedConnectionId}
            style={{ minWidth: '200px' }}
            onChange={(v) => setSelectedConnection(v)}
            placeholder="Select a connection"
        >
            {
                connections && connections.length !== 0
                ? connections.map((c) => <Select.Option value={c.id} key={c.id} disabled={!c.connected}>{ c.name }</Select.Option>)
                : <Select.Option value="">No connections</Select.Option>
            }
        </Select>
    );

    const onReloadClick = () => {
        setRefreshing(true);

        setTimeout(() => setRefreshing(false), 500);
    };

    const content = (
        <React.Fragment>
            { tool && tool.id === TEST_TOOL_ID ? <Button onClick={onReloadClick}>Reload</Button> : null }
            { connectionSelector }
        </React.Fragment>
    );

    return (
        <PageContainer
            loading={loading || loadingConnections}
            title={tool && !loading && !loadingConnections ? `${ tool.name } v${ tool.latestVersion?.version || '0.0.0'}` : 'Loading...'}
            subTitle={ tool && tool.id === TEST_TOOL_ID ? frameSrc : null}
            content={content}
            breadcrumb={breadcrumbProps}
        >
            { loading || !tool
                ? null
                : <ToolSandbox
                    navigate={navigate}
                    searchParams={searchParams}
                    tool={tool}
                    refresh={refreshing}
                    frameSourceUpdated={setFrameSrc}
                    connection={connections?.find((c) => c.id === selectedConnectionId)}
                />
            }
        </PageContainer>
    );
};
