import React from 'react';
import { Button, Result, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ITool } from 'models/tool';

interface IToolNewFlowStep3Props {
    tool?: ITool;
}

export const ToolNewFlowStep3: React.FC<IToolNewFlowStep3Props> = (props) => {
    const navigate = useNavigate();

    if (!props.tool || !props.tool.latestVersion) {
        return (
            <Result
                status="warning"
                title="Something went wrong (no tool)?"
            />
        );
    }

    const subTitle = (
        <Typography.Text disabled>You have successfully submitted your tool <Typography.Text disabled strong>{ props.tool.name }</Typography.Text> to be reviewed. We will be in contact with you soon.</Typography.Text>
    );

    return (
        <Result
            status="success"
            title={`${ props.tool.name } v${ props.tool.latestVersion.version } Submitted!`}
            subTitle={subTitle}
            extra={[
                <Button type="primary" key="action-btn" onClick={() => navigate('/tools?tab=mine')}>
                    Go to Tools
                </Button>
            ]}
        />
    );
};
