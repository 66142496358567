import React from 'react';
import { Input, Modal } from 'antd';

export function askForTestToolUrl(okText: React.ReactNode, cancelText: React.ReactNode): Promise<string> {
    let value = 'http://localhost:3000';

    return new Promise((resolve) => {
        function onOk() {
            resolve(value);
        }

        function onCancel() {
            resolve('');
        }

        Modal.confirm({
            title: 'What is the url?',
            content: <Input onChange={(e) => value = e.currentTarget.value} />,
            okText,
            onOk,
            onCancel,
            cancelText,
            autoFocusButton: 'ok',
        });
    });
}
