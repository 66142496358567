import React from 'react';
import moment from 'moment';

export const SimpleDate: React.FC<{ date?: string, simpler?: boolean }> = (props) => {
    const d = moment(props.date);

    if (!props.date || !d.isValid() || d.year() === 0 || d.year() === 1) {
        return (
            <span>-</span>
        );
    }

    return (
        <span>{ d.format(props.simpler ? 'l' : 'L') }</span>
    );
}
