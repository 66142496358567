import { getBearerToken } from "utils/auth";

export const BASE_URL = `${process.env.REACT_APP_API_URL}/v1`;
export const BASE_FILE_URL = `${process.env.REACT_APP_API_URL}/files`;

export interface IDownloadResult {
    url: string;
}

const handleError = async (response: Response) => {
    if (!response.ok) {
        console.log('handling error');
        let message;
        try {
            message = await response.json();
        } catch (e) {
            throw new Error(`request failed with status code: ${ response.status }`);
        }

        if (message && message.error) {
            throw new Error(message.error);
        } else {
            throw new Error(`request failed with status code: ${ response.status }`);
        }
    }
}

const addAuthentication = async (authenticationNeeded: boolean): Promise<{ Authorization: string }> => {
    if (authenticationNeeded) {
        return await getBearerToken();
    }

    return { Authorization: '' };
}

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
const download = async (response: Response) => {
    const disposition = response.headers.get('content-disposition');
    if (!disposition) {
        throw new Error('Invalid download request');
    }

    if (disposition.indexOf('attachment') === -1) {
        throw new Error('Invalid content disposition; must be attachment');
    }


    const matches = filenameRegex.exec(disposition);
    if (!matches || matches.length <= 2) {
        throw new Error('Invalid content disposition; no file name')
    }

    const filename = matches[1].replace(/['"]/g, '');
    const blob = await response.blob();

    let contentType = response.headers.get('content-type');
    if (!contentType) {
        contentType = 'application/octet-stream';
    }

    // downloadjs(blob, filename, contentType);
    console.log('we would be downloading:', filename, blob);
}

const get = async <TResult>(url: string, authenticated = false, req?: {}, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        headers: {
            ...await addAuthentication(authenticated),
            'X-Requested-With': 'PowerToolsUI',
        },
    };

    let fullUrl = BASE_URL + url;
    // if (req) {
    //     fullUrl = fullUrl + '?' + Object.keys(req).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(req[k])).join('&');
    // }

    const response = await fetch(fullUrl, config);

    await handleError(response);

    if (response.headers.get('content-disposition')) {
        await download(response);
        return {} as TResult;
    }

    return response.json();
}

const post = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        method: 'POST',
        headers: {
            ...await addAuthentication(authenticated),
            'X-Requested-With': 'PowerToolsUI',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

const postForm = async <TResult>(url: string, body: FormData, authenticated = false, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        method: 'POST',
        headers: {
            ...await addAuthentication(authenticated),
            'X-Requested-With': 'PowerToolsUI',
        },
        body,
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

const put = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        method: 'PUT',
        headers: {
            ...await addAuthentication(authenticated),
            'X-Requested-With': 'PowerToolsUI',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

const putForm = async <TResult>(url: string, body: FormData, authenticated = false, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        method: 'PUT',
        headers: {
            ...await addAuthentication(authenticated),
            'X-Requested-With': 'PowerToolsUI',
        },
        body,
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

const del = async (url: string, authenticated = false, opts?: { [key: string]: any }) => {
    const config = {
        method: 'DELETE',
        headers: {
            ...await addAuthentication(authenticated),
            'X-Requested-With': 'PowerToolsUI',
            'Content-Type': 'application/json'
        },
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

export { get, post, postForm, put, putForm, del };
