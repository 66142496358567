import React from 'react';
import Websandbox from '@jetbrains/websandbox';
import { NavigateFunction } from 'react-router-dom';
import type { useSearchParams } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import download from 'downloadjs';

import { ITool } from 'models/tool';
import { InternalApi } from 'models/internalApi';
import { IConnection } from 'models/connection';
import { IProxyRequestHeaders } from 'models/payloads/proxy';

import { TEST_TOOL_ID } from 'utils/constants';

import { askForTestToolUrl } from './askForTestToolUrl';

import { BASE_FILE_URL } from 'api';
import { proxyRequest } from 'api/proxy';

interface IToolSandboxProps {
    navigate: NavigateFunction;
    searchParams: ReturnType<typeof useSearchParams>;
    tool: ITool;
    connection?: IConnection;
    refresh: boolean;
    frameSourceUpdated?: (frameSrc: string) => void;
}

interface IToolSandboxState {
    loading: boolean;
    frameSrc: string;
}

export class ToolSandbox extends React.PureComponent<IToolSandboxProps, IToolSandboxState> {
    state: Readonly<IToolSandboxState> = {
        loading: true,
        frameSrc: '',
    };

    sandbox: Websandbox | undefined;
    asking: boolean | undefined;

    api: InternalApi = {
        loaded: async () => {
            this.setState({ loading: false }, () => console.log('sandbox loaded!'));

            this.connectionChanged();
        },
        get: async (url: string, query?: string, headers?: IProxyRequestHeaders) => {
            if (!url) {
                throw new URIError('request url must not be empty');
            }

            if (!this.props.connection) {
                throw new Error('no connection selected yet');
            }

            //TODO: validate the url they provide?
            //TODO: verify the headers they send? or rather, limit the headers? Prefer, Accept, odata ones

            if (typeof query === 'string' && query.length !== 0 && !url.includes('?')) {
                url = `${ url }?${ query.toString() }`;
            }

            return await proxyRequest({ method: 'GET', url, headers, toolId: this.props.tool.id, connectionId: this.props.connection.id });
        },
        post: async (url: string, body?: string, headers?: IProxyRequestHeaders) => {
            if (!url) {
                throw new URIError('request url must not be empty');
            }

            if (!this.props.connection) {
                throw new Error('no connection selected yet');
            }

            return await proxyRequest({ method: 'POST', url, body, headers, toolId: this.props.tool.id, connectionId: this.props.connection.id });
        },
        download: async (data: string, fileName?: string, mimeType?: string) => {
            if (typeof data !== 'string') {
                throw new Error('invalid data type, must only be a string right now');
            }

            download(data, fileName, mimeType);
        }
    };

    async componentDidMount() {
        if (this.sandbox || this.asking) {
            return;
        }

        const [searchParams, setSearchParams] = this.props.searchParams;

        let frameSrc = `${ BASE_FILE_URL }/${ this.props.tool.id }/${ this.props.tool.latestVersion!.entryFile }`;
        if (this.props.tool.id === TEST_TOOL_ID) {
            let frameUrl = searchParams.get('frameUrl') || '';

            if (frameUrl !== '') {
                frameSrc = frameUrl;
            } else {
                this.asking = true;

                frameSrc = await askForTestToolUrl('Continue', 'Nevermind');

                if (frameSrc === '') {
                    Modal.destroyAll();
                    this.props.navigate('/tools?tab=mine');
                    return;
                }

                this.asking = false;

                setSearchParams({ frameUrl: frameSrc });
            }
        }

        if (typeof this.props.frameSourceUpdated === 'function') {
            this.props.frameSourceUpdated(frameSrc);
        }

        this.setState({ frameSrc }, this.loadSandbox);
    }

    componentDidUpdate(prevProps: IToolSandboxProps) {
        if (!prevProps.refresh && this.props.refresh) {
            this.loadSandbox();
        }

        if (prevProps.connection === this.props.connection) {
            return;
        }

        if (this.state.loading) {
            return;
        }

        this.connectionChanged();
    }

    loadSandbox = () => {
        if (this.sandbox) {
            this.sandbox.destroy();
            this.sandbox = undefined;
        }

        this.sandbox = Websandbox.create(this.api, {
            frameContainer: '.tool_iframe_container',
            frameClassName: 'simple_iframe',
            frameSrc: this.state.frameSrc,
            allowAdditionalAttributes: 'allow-top-navigation-to-custom-protocols allow-top-navigation-by-user-activation allow-top-navigation allow-popups',
        });
        
        this.sandbox.promise.catch((e) => console.warn('failed to load the sandbox:', e));
    }

    connectionChanged = () => {
        if (!this.sandbox || !this.sandbox.connection) {
            return;
        }

        try {
            this.sandbox.connection!.remote.connectionChanged(this.props.connection?.name || undefined);
        } catch (e) {
            console.log('failed on the remote way:', e);
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="tool_iframe_container"></div>
            </Spin>
        );
    }
}
