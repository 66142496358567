import React from 'react';
import { Dropdown, Button, Menu, message } from 'antd';
import { DownOutlined, QuestionOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export type ActionMenuItemMap<TRecord> = { [actionKey: string] : IActionMenuItem<TRecord> };

export interface IActionMenuItem<TRecord> {
    isDivider?: boolean;
    isSubMenu?: boolean;
    items?: ActionMenuItemMap<TRecord>;
    icon?: React.ReactNode;
    text?: string;
    hidden?: (record: TRecord, actionKey: string) => boolean;
    disabled?: (record: TRecord, actionKey: string) => boolean;
    action?: (record: TRecord, actionKey: string) => Promise<void>;
}

interface IActionsMenuProps<TRecord> {
    record: TRecord;
    actions: ActionMenuItemMap<TRecord>;
    asButton?: boolean;
    onClick?: (record: TRecord, actionKey: string) => Promise<void>;
}

interface IActionsMenuState { }

export class ActionsMenu<TRecord> extends React.PureComponent<IActionsMenuProps<TRecord>, IActionsMenuState> {
    state: Readonly<IActionsMenuState> = {};

    onMenuClick = (e: MenuInfo) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();

        let topLevelKey = e.key;
        if (e.keyPath.length > 1) {
            topLevelKey = e.keyPath.reverse()[0];
        }

        let action = this.props.actions[topLevelKey];
        if (!action) {
            message.warn(`Unknown action clicked: ${ e.key }`);
            return;
        }

        if (action.isDivider) {
            return;
        }

        if (action.isSubMenu && typeof action.items !== 'undefined') {
            action = action.items[e.key];
        }

        if (typeof action.action === 'function') {
            action.action(this.props.record, e.key as string);
            return;
        }

        if (typeof this.props.onClick === 'function') {
            this.props.onClick(this.props.record, e.key as string);
            return;
        }

        message.warn(`The action "${ e.key }" does not have a valid action function.`);
    }

    mapActionsToMenuItems = (actionMap: ActionMenuItemMap<TRecord>, requireIcon: boolean): ItemType[] => {
        return Object.keys(actionMap).map((actionKey: string) => {
            const action = actionMap[actionKey];

            if (action.isDivider) {
                return {
                    key: actionKey,
                    type: 'divider',
                };
            }

            if (typeof action.hidden === 'function' && action.hidden(this.props.record, actionKey)) {
                return null;
            }

            if (action.isSubMenu) {
                if (!action.items || Object.keys(action.items).length === 0) {
                    console.warn('Invalid submenu action item! At least one item is required.');
                    return null;
                }

                return {
                    key: actionKey,
                    type: 'submenu',
                    label: <React.Fragment>{ action.icon ? action.icon : <QuestionOutlined /> } { action.text ? action.text : 'invalid action' }</React.Fragment>,
                    children: this.mapActionsToMenuItems(action.items, false),
                };
            }

            let icon = action.icon;
            if (requireIcon && !icon) {
                icon = <QuestionOutlined />;
            }

            return {
                key: actionKey,
                label: <React.Fragment>{ icon } { action.text ? action.text : 'invalid action' }</React.Fragment>,
                disabled: action.disabled ? action.disabled(this.props.record, actionKey) : false,
            };
        });
    }

    get overlay() {
        return (
            <Menu onClick={this.onMenuClick} items={this.mapActionsToMenuItems(this.props.actions, true)} />
        );
    }

    render() {
        return (
            <Dropdown overlay={this.overlay}>
                <Button type={this.props.asButton ? 'default' : 'link'}>
                    Actions <DownOutlined />
                </Button>
            </Dropdown>
        );
    }
}
